import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../../components/Layout/index'
import PressRelease from '../../components/PressRelease'
import SEO from '../../components/seo'
import Footer from '../../modules/Footer'
import Header from '../../modules/Header'

import Image from '../../images/news/press-release-contentstack-sm.jpg'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import messages from '../../messages/pageTitles.lang'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsContentstack)}
        imageAlt={formatMessage(messages.TitleNewsContentstack)}
        description={formatMessage(metaDescription.NewsContentstackDescription)}
        image={Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.contentstack}
        articleLinks={{
          contentstack: (
            <a
              href="https://www.contentstack.com/marketplace/freshcells-systems-engineering-gmbh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.contentstack.com/marketplace/freshcells-systems-engineering-gmbh/
            </a>
          ),
          hcms: (
            <Link to="/services/headless-cms-integration/contentstack/">
              {`https://www.freshcells.de/${locale}/services/headless-cms-integration/contentstack/`}
            </Link>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
